var columns = [
// {
//     title: '序号',
//     dataIndex: 'id',
//     key: 'id',
//     width: '20%',
//     scopedSlots: {
//         customRender: 'id'
//     }
// },
{
  title: 'banner名称',
  dataIndex: 'name',
  key: 'name',
  width: '20%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: 'banner图片',
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '20%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '内容',
  dataIndex: 'content',
  key: 'content',
  width: '30%',
  scopedSlots: {
    customRender: 'content'
  }
}, {
  title: '开始时间—结束时间',
  dataIndex: 'begintime',
  key: 'begintime',
  width: '30%',
  scopedSlots: {
    customRender: 'begintime'
  }
}, {
  title: '操作',
  key: 'banneraction',
  dataIndex: 'banneraction',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'banneraction'
  }
}];
export { columns };